import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when login action starts
    try {
      const res = await axios.post(`${process.env.REACT_APP_DOMAIN_URL}/admin/login`, { username, password });
      localStorage.setItem('token', res.data.token);
      navigate('/manage-books');
    } catch (error) {
      // Handle errors here
      if (error.response && error.response.data) {
        setError(error.response.data.message);
      } else {
        setError('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false); // Reset loading state regardless of success or failure
    }
  };

  return (
    <div className="flex flex-col justify-center w-full p-8">
      <form onSubmit={handleSubmit} className="p-8 bg-slate-500 rounded-lg shadow-md lg:w-[60%] lg:self-center">
        <h2 className="text-2xl font-bold mb-4">Login</h2>
        <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4 outline-none text-black" />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4 outline-none text-black" />
        <button type="submit" disabled={loading} className={`w-full bg-blue-500 hover:bg-blue-600 text-white rounded-md py-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
        <Link to='/change-password' className='pt-3 block text-indigo-900 font-semibold'>Change Password?</Link>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </form>
    </div>
  );
};

export default Login;
