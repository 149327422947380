import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const EditLecture = () => {
    const [data, setData] = useState({
        title: '',
        description: ''
    });
    const { id } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state for lecture update

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_DOMAIN_URL}/lecture/${id}`);
                setData(res.data);
            } catch (error) {
                console.error('Error fetching data:', error.message);
                setError('Error fetching lecture data. Please try again.');
            }
        };

        fetchData();
    }, [id]);

    const handleEditLecture = async () => {
        setLoading(true); // Set loading to true when lecture update starts
        try {
            const lectureData = {
                title: data.title,
                description: data.description
            };

            await axios.put(`${process.env.REACT_APP_DOMAIN_URL}/lecture/${id}`, lectureData, {
                withCredentials: true
            });

            navigate('/manage-lectures');
        } catch (error) {
            console.error('Error editing lecture:', error);
            setError('Error editing lecture. Please try again.');
        } finally {
            setLoading(false); // Reset loading state regardless of success or failure
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setData({
            ...data,
            [name]: value
        });
    };

    return (
        <div className="w-full p-2 sm:p-8">
            <h1 className="text-3xl font-semibold mb-8 mx-4">UPDATE LECTURE</h1>
            <div className="">
                <div className="text-2xl text-black">
                    <div className="p-4 rounded-md lg:flex gap-8">
                        <div className="flex flex-col gap-8 w-full lg:w-1/2">
                            <input type="text" placeholder="Title" required className="border rounded-md p-2 outline-none" name="title" value={data.title} onChange={handleChange} />
                            <textarea rows={7} type="text" placeholder="Description" required className="border rounded-md p-2 outline-none" name="description" value={data.description} onChange={handleChange} />
                            <button onClick={handleEditLecture} disabled={loading} className={`bg-blue-500 hover:bg-blue-600 text-white rounded-md py-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                                {loading ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </div>
                </div>
                {error && <div className="text-red-500 text-center text-xl mt-4">{error}</div>}
            </div>
        </div>
    );
};

export default EditLecture;
