import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Subscribers = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [currentPage, setCurrentPage] = useState(1);
  const [subscribersPerPage] = useState(7);
  const [isSending, setIsSending] = useState(false); // Add state to track sending status

  useEffect(() => {
    // Fetch subscribers from backend when component mounts
    const fetchSubscribers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_DOMAIN_URL}/subscribe/subscribers`);
        setSubscribers(response.data.subscribers);
        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        console.error('Error fetching subscribers:', error);
        setLoading(false); // Also set loading to false in case of error
      }
    };

    fetchSubscribers();
  }, []);

  // Get current subscribers
  const indexOfLastSubscriber = currentPage * subscribersPerPage;
  const indexOfFirstSubscriber = indexOfLastSubscriber - subscribersPerPage;
  const currentSubscribers = subscribers.slice(indexOfFirstSubscriber, indexOfLastSubscriber);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to handle sending email to all subscribers
  const sendEmailToAllSubscribers = async () => {
    setIsSending(true); // Set sending status to true
    try {
      const emails = subscribers.map(subscriber => subscriber.email).join(';'); // Join emails with semicolon
      window.location.href = `mailto:${emails}`; // Redirect to email client with all subscribers' emails
    } catch (error) {
      console.error('Error sending email:', error);
    } finally {
      setIsSending(false); // Reset sending status regardless of success or failure
    }
  };

  return (
    <div className="p-2 sm:p-8 w-full">
      <h2 className="text-3xl font-semibold mb-8 mx-4">Subscribers</h2>
      {loading ? ( // Render spinner while loading
        <div className="flex justify-center items-center mt-8">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-yellow-500"></div>
        </div>
      ) : (
        <>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-slate-500">
              <tr>
                <th className="hidden sm:inline-block px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Email</th>
              </tr>
            </thead>
            <tbody className="bg-transparent divide-y divide-gray-200">
              {currentSubscribers.map((subscriber, index) => (
                <tr key={index}>
                  <td className="hidden sm:inline-block px-6 py-4 whitespace-nowrap">{index + 1 + (currentPage - 1) * subscribersPerPage}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{subscriber.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination */}
          <div className="mt-4 flex justify-center">
            {Array.from({ length: Math.ceil(subscribers.length / subscribersPerPage) }, (_, index) => (
              <button key={index} onClick={() => paginate(index + 1)} className="mx-1 px-3 py-1 bg-slate-500 text-white rounded-md hover:bg-slate-600">
                {index + 1}
              </button>
            ))}
          </div>
          {/* Button to send email to all subscribers */}
          <div className="mt-4 flex justify-center">
            <button onClick={sendEmailToAllSubscribers} disabled={isSending} className={`bg-blue-500 hover:bg-blue-600 text-white rounded-md py-2 px-2 ${isSending ? 'opacity-50 cursor-not-allowed' : ''}`}>
              {isSending ? 'Sending...' : 'Send to All'}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Subscribers;
