import React, { useEffect, useState } from 'react';
import axios from 'axios';

const UpdateAboutSec = () => {
    const [desc, setDesc] = useState('');
    const [id, setId] = useState('');
    const [loading, setLoading] = useState(true); // Add loading state
    const [isUpdating, setIsUpdating] = useState(false); // Add state to track update status

    useEffect(() => {
        const fetchAbout = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_DOMAIN_URL}/about`);
                setId(res.data[0]._id);
                setDesc(res.data[0].desc);
                setLoading(false); // Set loading to false after fetching data
            } catch (error) {
                console.log(error);
                setLoading(false); // Also set loading to false in case of error
            }
        };

        fetchAbout(); // Call the fetchAbout function
    }, []); // Empty dependency array to ensure the effect runs only once after initial render

    const handleUpdate = async () => {
        try {
            setIsUpdating(true); // Set updating status to true
            const res = await axios.put(`${process.env.REACT_APP_DOMAIN_URL}/about/${id}`, { desc }, {
                withCredentials: true
            });
            console.log('Update successful:', res.data);
            alert("About section has been updated successfully")

        } catch (error) {
            console.error('Error updating about section:', error);
        } finally {
            setIsUpdating(false); // Reset updating status regardless of success or failure
        }
    };

    return (
        <div className='p-2 sm:p-8 w-full flex flex-col '>
            <h1 className='text-3xl font-semibold mb-8 mx-4'>Update-About </h1>
            {loading ? ( // Show spinner if loading
                <div className="flex justify-center items-center mt-8">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-yellow-500"></div>
                </div>
            ) : (
                <>
                    <textarea
                        rows={20}
                        type='text'
                        placeholder='Description'
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        className='border border-gray-300 rounded-md px-3 py-2 mb-4 mx-4 text-black  outline-none lg:w-1/2'
                    />
                    <button
                        onClick={handleUpdate}
                        className={`bg-blue-500 text-white px-4 mx-4 py-2 rounded-md hover:bg-blue-600  lg:w-1/2 ${isUpdating ? 'opacity-50 cursor-not-allowed' : ''}`} // Disable button when updating
                        disabled={isUpdating} // Disable button when updating
                    >
                        {isUpdating ? 'Updating...' : 'Update'}
                    </button>
                </>
            )}
        </div>
    );
};

export default UpdateAboutSec;
