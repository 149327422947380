import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Addbook = () => {
    const [error, setError] = useState('');
    const [data, setData] = useState({
        title: "",
        description: "",
        image: null,
        buybooklink: "",
        ebooklink: "",
        physicalbook: ""
    });
    const [loading, setLoading] = useState(false); // Add loading state for book creation
    const navigate = useNavigate();

    const handleCreateBook = async () => {
        setLoading(true); // Set loading to true when book creation starts
        try {
            const formData = new FormData();
            formData.append('title', data.title);
            formData.append('description', data.description);
            formData.append('image', data.image); // Append the image file
            formData.append('buybooklink', data.buybooklink);
            formData.append('ebooklink', data.ebooklink);
            formData.append('physicalbook', data.physicalbook);

            await axios.post(`${process.env.REACT_APP_DOMAIN_URL}/books`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data' // Set content type for file upload
                }
            });

            setData({
                title: "",
                description: "",
                image: null,
                buybooklink: "",
                ebooklink: "",
                physicalbook: ""
            });
            navigate('/manage-books');
        } catch (error) {
            console.error('Error creating book:', error);
            if (error.response && error.response.data && error.response.data.message) {
                // Extract the error message from the response
                setError(error.response.data.message);
            } else {
                setError('Error creating book. Please try again.');
            }
        } finally {
            setLoading(false); // Reset loading state regardless of success or failure
        }
    };

    const handleChange = (e) => {
        if (e.target.type === 'file') {
            const file = e.target.files[0];
            setData({
                ...data,
                image: file,
                imageUrl: URL.createObjectURL(file) // Create temporary URL for the uploaded image
            });
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            });
        }
    };

    return (
        <div className=' p-2 sm:p-8 w-full  ' >
            <h1 className="text-3xl font-semibold mb-4 mx-4 ">UPLOAD BOOK</h1>
            <div className='text-2xl text-black'>
                <div className=' p-4 rounded-md lg:flex  gap-8 '>
                    <div className='flex flex-col gap-8 w-full lg:w-1/2'>
                        <input type='text' placeholder='* Title' required className=' border-2 border-red-700 rounded-md p-2 outline-none' name='title' value={data.title} onChange={handleChange} />
                        <textarea rows={7} type='text' placeholder='* Description' required className='border-2 border-red-700 rounded-md p-2 outline-none' name='description' value={data.description} onChange={handleChange} />
                        <input type='file' required className='border-2 border-red-700 rounded-md p-2 outline-none mb-3 lg:mb-0' name='image' onChange={handleChange} />
                        {data.imageUrl && <img src={data.imageUrl} alt={data.title} className='w-[150px] h-[120px]  object-contain mb-5 sm:mb-0' />}
                    </div>
                    <div className='flex flex-col gap-8 w-full lg:w-1/2'>
                        <input type='text' placeholder='Url of buying the book' className='border rounded-md p-2 outline-none' name='buybooklink' value={data.buybooklink} onChange={handleChange} />
                        <input type='text' placeholder='Url of buying E-book' className='border rounded-md p-2 outline-none' name='ebooklink' value={data.ebooklink} onChange={handleChange} />
                        <input type='text' placeholder='Url of some libraries where the book is available' className='border rounded-md p-2 outline-none' name='physicalbook' value={data.physicalbook} onChange={handleChange} />
                        <button onClick={handleCreateBook} disabled={loading} className={`bg-blue-500 hover:bg-blue-600 text-white rounded-md py-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                            {loading ? 'Creating...' : 'Create'}
                        </button>
                    </div>
                </div>
            </div>
            {error && <div className="text-red-500 text-center text-xl mt-4">{error}</div>}
        </div>
    );
};

export default Addbook;
