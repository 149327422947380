import axios from 'axios';
import {  NavLink, useNavigate } from 'react-router-dom';


const Sidebar = () => {
  const navigate= useNavigate()
  const handleLogout = async () => {
    try {
      // Remove the token from localStorage
      localStorage.removeItem('token');

      // Send a request to the server to clear the token cookie
      await axios.post(`${process.env.REACT_APP_DOMAIN_URL}/admin/logout`);
      alert("you have logged out successfully")

      // Navigate to the login page or any other desired route
      navigate('/login');
    } catch (error) {
      // Handle errors if needed
      console.error('Error logging out:', error);
      // Optionally, show an error message to the user
    }
  };

 
  return (
    <div className="bg-slate-700 w-[120%] text-white  sm:w-[317px]  sm:min-h-svh p-4  " >

        <h2 className="text-xl font-semibold mb-8 w-full">kristoffer j.andersen</h2>
        <ul>
          <li className="mb-4">
            <NavLink to="/manage-books" className="text-lg font-medium" activeClassName="active">Manage Books</NavLink>
          </li>
          <li className="mb-4">
            <NavLink to="/manage-lectures" className="text-lg font-medium" activeClassName="active">Manage Lectures</NavLink>
          </li>
         
          <li className="mb-4">
            <NavLink to="/add-book" className="text-lg font-medium" activeClassName="active">Add Book</NavLink>
          </li>
          <li className="mb-4">
            <NavLink to="/add-lecture" className="text-lg font-medium" activeClassName="active">Add Lecture</NavLink>
          </li>
          <li className="mb-4">
            <NavLink to="/update-about" className="text-lg font-medium" activeClassName="active">Update-About-Bio</NavLink>
          </li>
          <li className="mb-4">
            <NavLink to="/subscribers" className="text-lg font-medium" activeClassName="active">Subscribers</NavLink>
          </li>
          <li className="mb-4">
            <NavLink to="/login" className="text-lg font-medium" activeClassName="active">Login</NavLink>
          </li>
          <li className="mb-4">
            <button  className="text-lg font-medium" activeClassName="active" onClick={handleLogout}>Logout</button>
          </li>
        </ul>

    </div>
  );
};

export default Sidebar;

