import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const Editbook = () => {
    const [data, setData] = useState({
        title: '',
        description: '',
        image: null,
        buybooklink: '',
        ebooklink: '',
        physicalbook: ''
    });
    const { id } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state for book update

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_DOMAIN_URL}/books/get/${id}`);
                setData(res.data);
            } catch (error) {
                console.error('Error fetching data:', error.message);
                setError('Error fetching book data. Please try again.');
            }
        };

        fetchData();
    }, [id]);

    const handleEditBook = async () => {
        setLoading(true); // Set loading to true when book update starts
        try {
            const formData = new FormData();
            formData.append('title', data.title);
            formData.append('description', data.description);
            formData.append('image', data.image);
            formData.append('buybooklink', data.buybooklink);
            formData.append('ebooklink', data.ebooklink);
            formData.append('physicalbook', data.physicalbook);

            await axios.put(`${process.env.REACT_APP_DOMAIN_URL}/books/${id}`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // After updating the book, fetch the updated data and update the state
            const updatedRes = await axios.get(`${process.env.REACT_APP_DOMAIN_URL}/books/get/${id}`);
            setData(updatedRes.data); // Update the state with the updated book data

            navigate('/manage-books');
        } catch (error) {
            console.error('Error editing book:', error);
            setError('Error editing book. Please try again.');
        } finally {
            setLoading(false); // Reset loading state regardless of success or failure
        }
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'image' && files && files[0]) {
            // Create URL for the selected image
            const imageUrl = URL.createObjectURL(files[0]);
            setData({
                ...data,
                [name]: files[0],
                imageUrl: imageUrl // Save the URL to display the image immediately
            });
        } else {
            setData({
                ...data,
                [name]: value
            });
        }
    };

    return (
        <div className="w-full p-2 sm:p-8">
            <h1 className="text-3xl font-semibold mb-8 mx-4">UPDATE BOOK</h1>
            <div className="">
                <div className="text-2xl text-black">
                    <div className="p-4 rounded-md lg:flex gap-8">
                        <div className="flex flex-col gap-8 w-full lg:w-1/2 ">
                            <input type="text" placeholder="Title" required className="border rounded-md p-2 outline-none" name="title" value={data.title} onChange={handleChange} />
                            <textarea rows={7} type="text" placeholder="Description" required className="border rounded-md p-2 outline-none" name="description" value={data.description} onChange={handleChange} />
                            <input type="file" required className="border rounded-md p-2 outline-none" name="image" onChange={handleChange} />
                            <img src={data.imageUrl || `${process.env.REACT_APP_IMAGE_URL}/${data.image}`} alt={data.title} className="w-[80px] h-[80px]" />
                        </div>
                        <div className="flex flex-col gap-8 w-full lg:w-1/2">
                            <input type="text" placeholder="Url of buying the book" className="border rounded-md p-2 outline-none" name="buybooklink" value={data.buybooklink} onChange={handleChange} />
                            <input type="text" placeholder="Url of buying E-book" className="border rounded-md p-2 outline-none" name="ebooklink" value={data.ebooklink} onChange={handleChange} />
                            <input type="text" placeholder="Url of some libraries where the book is available" className="border rounded-md p-2 outline-none" name="physicalbook" value={data.physicalbook} onChange={handleChange} />
                            <button onClick={handleEditBook} disabled={loading} className={`bg-blue-500 hover:bg-blue-600 text-white rounded-md py-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                                {loading ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </div>
                </div>
                {error && <div className="text-red-500 text-center text-xl mt-4">{error}</div>}
            </div>
        </div>
    );
};

export default Editbook;
