import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ManageBooks = () => {
    const [books, setBooks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [booksPerPage] = useState(6);
    const [error, setError] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [bookToDelete, setBookToDelete] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state
    const [isDeleting, setIsDeleting] = useState(false); // Add deleting state

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_DOMAIN_URL}/books`);
                setBooks(res.data);
                setLoading(false); // Set loading to false after fetching data
            } catch (error) {
                console.error("Error fetching data:", error.message);
                setError("Error fetching data. Please try again.");
                setLoading(false); // Also set loading to false in case of error
            }
        };
        fetchData();
        window.scrollTo(0, 0);
    }, []);

    const onDelete = async (id) => {
        setIsDeleting(true); // Set deleting state to true when delete action starts
        try {
            await axios.delete(`${process.env.REACT_APP_DOMAIN_URL}/books/${id}`, {
                withCredentials: true
            });
            setBooks(books.filter(book => book._id !== id));
            setError(""); // Clear error if deletion is successful
            closeModal()
        } catch (error) {
            console.log(error);
            setError("Error deleting book. Please try again.");
        } finally {
            setIsDeleting(false); // Reset deleting state regardless of success or failure
        }
    };

    // Function to open the modal
    const openModal = (id) => {
        setShowModal(true);
        setBookToDelete(id);
    };

    // Function to close the modal
    const closeModal = () => {
        setShowModal(false);
        setBookToDelete(null);
    };

    const indexOfLastBook = currentPage * booksPerPage;
    const indexOfFirstBook = indexOfLastBook - booksPerPage;
    const currentBooks = books.slice(indexOfFirstBook, indexOfLastBook);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="flex flex-col justify-center w-full p-2 sm:p-8">
            <h2 className='text-3xl font-semibold mb-8 mx-4'>Manage Books</h2>
            {loading ? ( // Show spinner if loading
                <div className="flex justify-center items-center mt-8">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-yellow-500"></div>
                </div>
            ) : (
                <>
                    {error && <div className="text-red-500 mb-2">{error}</div>}
                    <table className="table-auto w-full">
                        <thead >
                            <tr className="bg-slate-500">
                                <th className="border px-4 py-2">#</th>
                                <th className="border px-4 py-2">Book-cover</th>
                                <th className="border px-4 py-2">Title</th>
                                <th className="border px-4 py-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentBooks.map((book, index) => (
                                <tr key={book._id} className="bg-transparent text-center">
                                    <td className="border px-4 py-2">{indexOfFirstBook + index + 1}</td>
                                    <td className="border px-4 py-2 flex justify-center items-center"><img src={`${process.env.REACT_APP_IMAGE_URL}/${book.image}`}  alt={book.title} className='w-[80px] h-[80px] rounded-sm' /></td>
                                    <td className="border px-4 py-2">{book.title}</td>
                                    <td className="border px-4 py-2">
                                        <Link to={`/edit-book/${book._id}`} className="bg-blue-500 hover:bg-blue-600  text-white px-2 py-1 rounded" >Edit</Link>
                                        <button className={`bg-red-500 text-white px-2 py-1 rounded ml-2 mt-3 md:mt-0 ${isDeleting && 'opacity-50 cursor-not-allowed'}`} onClick={() => openModal(book._id)} disabled={isDeleting}>
                                            {isDeleting ? 'Deleting...' : 'Delete'}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {/* Pagination */}
                    <div className="flex justify-center my-6">
                        {[...Array(Math.ceil(books.length / booksPerPage)).keys()].map(number => (
                            <button key={number} onClick={() => paginate(number + 1)} className="mx-1 px-3 py-1 bg-slate-500 text-white rounded-md hover:bg-slate-600">
                                {number + 1}
                            </button>
                        ))}
                    </div>
                    {/* Modal */}
                    {showModal && (
                        <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-900 bg-opacity-50">
                            <div className="bg-white p-8 rounded-md">
                                <h2 className="text-xl font-semibold mb-4 text-black">Are you sure you want to delete this book?</h2>
                                <div className="flex justify-end">
                                    <button className="bg-red-500 text-white px-4 py-2 rounded-md mr-2" onClick={() => onDelete(bookToDelete)} disabled={isDeleting}>
                                        {isDeleting ? 'Deleting...' : 'Yes, I want to delete'}
                                    </button>
                                    <button className="bg-gray-500 text-white px-4 py-2 rounded-md" onClick={closeModal}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default ManageBooks;
