import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when password change action starts
    try {
      // Make a POST request to the server endpoint
      await axios.post(`${process.env.REACT_APP_DOMAIN_URL}/admin/change-password`, { oldPassword, newPassword });
      alert("Password has been changed successfully");
      // Redirect to the login page after successful password change
      navigate("/login");
    } catch (error) {
      // Handle errors here
      if (error.response && error.response.data) {
        setError(error.response.data.message);
      } else {
        setError('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false); // Reset loading state regardless of success or failure
    }
  };

  return (
    <div className="flex flex-col justify-center w-full p-8">
      <form onSubmit={handleChangePassword} className="p-8 bg-slate-500 rounded-lg shadow-md lg:w-[60%] lg:self-center">
        <h2 className="text-2xl font-bold mb-4">Change Password</h2>
        <input type="password" placeholder="Old Password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4 outline-none text-black" />
        <input type="password" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4 outline-none text-black" />
        <button type="submit" disabled={loading} className={`w-full bg-blue-500 text-white rounded-md py-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
          {loading ? 'Changing Password...' : 'Change Password'}
        </button>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </form>
      {/* Link to login page */}
      <Link to="/login" className="text-indigo-400 font-semibold mt-4 block text-center">Go back to login</Link>
    </div>
  );
};

export default ChangePassword;
