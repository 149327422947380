import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddLecture = () => {
    const [error, setError] = useState('');
    const [data, setData] = useState({
        title: '',
        description: ''
    });
    const [loading, setLoading] = useState(false); // Add loading state for lecture creation
    const navigate = useNavigate();

    const handleCreateLecture = async () => {
        setLoading(true); // Set loading to true when lecture creation starts
        try {
            const lectureData = {
                title: data.title,
                description: data.description,
                // Add any other fields you need to include in the request body
            };

            await axios.post(`${process.env.REACT_APP_DOMAIN_URL}/lecture`, lectureData, {
                withCredentials: true
            });

            setData({
                title: '',
                description: ''
            });
            navigate('/manage-lectures');
        } catch (error) {
            console.error('Error creating lecture:', error);
            if (error.response && error.response.data && error.response.data.message) {
                // Extract the error message from the response
                setError(error.response.data.message);
            } else {
                setError('Error creating lecture. Please try again.');
            }
        } finally {
            setLoading(false); // Reset loading state regardless of success or failure
        }
    };

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="p-2 sm:p-8 w-full">
            <h1 className="text-3xl font-semibold mb-4 mx-4">Add Lecture</h1>
            <div className="text-2xl text-black">
                <div className="p-4 rounded-md lg:flex gap-8">
                    <div className="flex flex-col gap-8 w-full lg:w-1/2">
                        <input type="text" placeholder="* Title" required className="border-2 border-red-700 rounded-md p-2 outline-none" name="title" value={data.title} onChange={handleChange} />
                        <textarea rows={7} type="text" placeholder="* Description" required className="border-2 border-red-700 rounded-md p-2 outline-none" name="description" value={data.description} onChange={handleChange} />
                        <button onClick={handleCreateLecture} disabled={loading} className={`bg-blue-500 hover:bg-blue-600 text-white rounded-md py-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                            {loading ? 'Creating...' : 'Create'}
                        </button>
                    </div>
                </div>
            </div>
            {error && <div className="text-red-500 text-center text-xl mt-4">{error}</div>}
        </div>
    );
};

export default AddLecture;
