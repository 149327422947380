import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    // Get the token and its expiration time from local storage
    const token = localStorage.getItem('token');

   // Check if the token exists and if its expiration time is valid
if (!token) {
    // // If the token is expired or not found, remove it from local storage and redirect to login
    // localStorage.removeItem('token');
    return <Navigate to="/login" replace />;
}


    // If the token is valid and not expired, render the children
    return children;
};

export default ProtectedRoute;
